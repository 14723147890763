import { DocumentText, Headphone, LanguageSquare, Lock, User } from 'iconsax-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./main.css"
import { useSelector } from 'react-redux'
function Settings() {
    const lang = useSelector(state => state.lang);
    return (
        <div className='settings px-2'>
            <h1>{lang.menu.settings}</h1>
            <Row xs={1} lg={2}>
                <Col className='mb-3'>
                    <Link to="personal-info" className="w-100 d-flex align-items-center bor-r16">
                        <span className='icon-box me-3'><User size="24" color="#7A7A7A" /></span>
                        {lang.settings.personal}
                    </Link>
                </Col>
                <Col className='mb-3'>
                    <Link to="change-lang" className="w-100 d-flex align-items-center bor-r16">
                        <span className='icon-box me-3'><LanguageSquare color="#7a7a7a" /></span>
                        {lang.settings.change_lang}
                    </Link>
                </Col>
                <Col className='mb-3'>
                    <Link to="change-password" className="w-100 d-flex align-items-center bor-r16">
                        <span className='icon-box me-3'><Lock color="#7a7a7a" /></span>
                        {lang.settings.change_password}
                    </Link>
                </Col>
                <Col className='mb-3'>
                    <Link to="privacy-and-policy" className="w-100 d-flex align-items-center bor-r16">
                        <span className='icon-box me-3'><DocumentText size="24" color="#7A7A7A" /></span>
                        {lang.settings.pravicy_policy}
                    </Link>
                </Col>
                <Col className='mb-3'>
                    <Link to="tel:+99888563333" className="w-100 d-flex align-items-center bor-r16">
                        <span className='icon-box me-3'><Headphone size="24" color="#7A7A7A" /></span>
                        {lang.settings.support}
                    </Link>
                </Col>
            </Row>

        </div>
    )
}

export default Settings