import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import { useAuth } from './Auth'
import axios from 'axios';
import { useCookies } from 'react-cookie';

function ProtectedRoute() {
    const params = useLocation()
    const [cookie] = useCookies()
    let isInner = params.pathname.split("/").length > 2;

    const auth = useAuth();
    let isAuthorized = !!auth.conformUser;

    /* Axios default request paramets with auth token */
    axios.interceptors.request.use(
        (request) => {
            // console.log(cookie.userToken, auth.conformUser, "axios defauld");
            request.headers[
                "Authorization"
            ] = `Bearer ${!!auth.conformUser ? auth.conformUser : cookie.userToken || localStorage.getItem('userToken')}`;
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use((response) => {
        // console.log(response);
        return response
    }, (error) => {
        console.log(error);
        if (error.response.status === 500 || error.response.status === 403) {
            localStorage.removeItem('userToken')
            window.location.href = '#/login';
        }
    })

    const location = useLocation()

    if (!isAuthorized) {
        return <Navigate to="/login" state={{ path: location.pathname }} />;
    }


    return (
        <>
            <Header img={""} name={"Alijon Kuvondikov"} inner={isInner} />
            <div className={`${isInner && "inner-body"} `}>
                <div className={`main mx-auto`}>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default ProtectedRoute