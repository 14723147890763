import React, { useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import InputFeild from '../../components/InputFeild/InputFeild'
import { useAuth } from '../../routes/Auth'
import { useSelector } from 'react-redux';
import { changePhone } from '../../components/Externel functions/specailHandleChange';
import { useForm } from 'react-hook-form';


function Login() {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const redirectLocation = location.state?.path || "/";

    const lang = useSelector(state => state.lang)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            phone: "+998",
            password: ""
        }
    });



    useEffect(() => {
        let platform = window.Telegram.WebApp.platform;

        // console.log(!auth.conformUser, auth.conformUser);
        if (platform !== "unknown" && !localStorage.getItem('userToken')) {
            window.Telegram.WebApp.requestContact(function (isSentContact, data) {
                if (isSentContact) {
                    auth.loginWithTelegram(data.response)
                }
            })
        }



        if (!!auth.conformUser) {
            navigate(redirectLocation)
        }
    }, [auth.conformUser, navigate, redirectLocation, auth])


    function sendData(data) {
        data = {
            ...data,
            phone: data.phone.replace(/\D/g, '')
        }
        auth.login(data, redirectLocation)
    }

    return (
        <div className="form-container container">
            <Row className="align-items-center justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <form className="form-box" onSubmit={handleSubmit(data => sendData(data))}>
                        <h1 className="title">{lang.login.welcome}</h1>
                        <p className="title-bottom">{lang.login.missed}</p>

                        <InputFeild
                            data={register("phone", {
                                required: true, minLength: { value: 17, message: lang.inputs.phone_required },
                                onChange: (e) => e.target.value = changePhone(e.target.value)
                            })}
                            placeholder={lang.inputs.phone}
                            type="text"
                            error={errors?.phone} />

                        <InputFeild
                            error={errors?.password}
                            data={register("password", { required: lang.inputs.password_required, minLength: { value: 6, message: lang.inputs.password_length_required } })}
                            placeholder={lang.inputs.password}
                            type="password" name={"password"} />

                        <p className='text-danger'>{auth.error}</p>

                        <button type="submit" className="button mt-3">
                            {!!auth.loading ? <Spinner className='me-2' animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            {lang.login.signin}</button>

                        {/* <p className="help-msg mt-3">{lang.login.reset_password} <Link to="/forgot">{lang.login.click}</Link></p> */}
                        <p className="help-msg mt-3">{lang.login.no_account} <Link to="/register">{lang.login.click}</Link></p>
                    </form>
                </Col>
            </Row>
        </div>
    )
}

export default Login