import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from '../../store/lang/langSlice';
import Select from 'react-select';

function ChangeLang() {
    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch();

    const options = [
        { label: "O'zbek", value: "uz" },
        { label: "Russian", value: "ru" },
        { label: "English", value: "en" },
        { label: "Ўзбек", value: "oz" },
    ]

    function handleChange({ value }) {
        dispatch(changeLang(value))
        localStorage.setItem("lang", value)
    }


    return (
        <div className='py-5 px-2'>
            <h1>{lang.settings.change_lang}</h1>
            <Select
                onChange={handleChange}
                className="custom-select mb-3"
                placeholder={lang.settings.select_lang}
                value={options.find(item => item.value === lang.lang)}
                options={options} />
        </div>
    )
}

export default ChangeLang