import { createSlice } from "@reduxjs/toolkit";

const applicationsSlice = createSlice({
  name: "application",
  initialState: [],
  reducers: {
    updateApplication(state, actions) {
      return actions.payload;
    },
  },
});

export const { updateApplication } = applicationsSlice.actions;
export default applicationsSlice.reducer;
