export const gender = {
  uz: [
    { value: 1, label: "Erkak" },
    { value: 0, label: "Ayol" },
  ],
  en: [
    { value: 1, label: "Male" },
    { value: 0, label: "Femail" },
  ],
  ru: [
    { value: 1, label: "Мужской" },
    { value: 0, label: "Женский" },
  ]
};
