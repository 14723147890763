import React, { useEffect, useState } from 'react'
import './main.css'
import { Col, Row } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clickUp from "../../assets/image/icons/click.png"
import payme from '../../assets/image/icons/payme.png'
import axios from 'axios';
import { separeteNumber } from '../../components/Externel functions/extraFunct';
import PromoCode from '../../components/modals/PromoCode';
import { MoneySend } from 'iconsax-react';
import PayFromWallet from '../../components/modals/PayFormWallet';
function Pay() {
    const [params] = useSearchParams();
    const [link, setLink] = useState("");
    const [data, setData] = useState();
    const lang = useSelector(state => state.lang)

    useEffect(() => {
        axios.post("payment/generate-link", {
            "info_id": params.get("id")
        }).then(res => {
            setData(res?.data?.data)
        })
    }, [params])

    function handleChange(e) {
        switch (e.target.value) {
            case "click": {
                setLink(data["click"])
                break;
            }
            case "payme": {
                setLink(data["payme"])
                break;
            }
            default: { }
        }
    }

    function redirectToLink() {
        window.location.replace(link)
    }

    return (
        <div>
            <h1>{lang.application.tariffs}</h1>
            <Row xs={1} lg={2} className='tariffs'>
                <Col className="tariffs-item">
                    <input className='d-none' value={"click"} id='click' type="radio" onChange={handleChange} name='click' />
                    <label className='d-flex justify-content-between align-items-center' htmlFor={"click"}>
                        <div>
                            <img src={clickUp} alt="" />
                            <span className='pay-text ms-2'>{lang.application.pay_from_click}</span>
                        </div>
                        <span className='pay-circle'></span>
                    </label>
                </Col>
                <Col className="tariffs-item">
                    <input className='d-none' value={"payme"} id='payme' type="radio" onChange={handleChange} name='click' />
                    <label className='d-flex justify-content-between align-items-center' htmlFor={"payme"}>
                        <div>
                            <img src={payme} alt="" />
                            <span className='pay-text ms-2'>{lang.application.pay_from_payme}</span>
                        </div>
                        <span className='pay-circle'></span>
                    </label>
                </Col>

            </Row>
            <Row className='tariffs'>
                <Col className="tariffs-item">
                    <div className='total-pay '>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className='total-text'>{lang.application.total}</span>
                            <span className='total-money'>{separeteNumber(data?.tariff_price)} uzs</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>

                            <span className='total-text'>{lang.application.wallet}</span>
                            <span className='total-money'>{data?.wallet_amount === 0 ? data?.wallet_amount : separeteNumber(data?.wallet_amount)} uzs</span>
                        </div>
                    </div>
                </Col>
            </Row>
            <PromoCode />
            {

                data?.wallet_amount > data?.tariff_price ?
                    <Row xs={1} md={2}>
                        <Col>
                            <button onClick={redirectToLink} className={`text-white button mt-4`} ><MoneySend className='me-2' size="32" color="#fff" />{lang.pay}</button>
                        </Col>
                        <Col>
                            <PayFromWallet />
                        </Col>
                    </Row> :
                    !link ?
                        <button className={`disabled button mt-4`} >{lang.pay}</button> :
                        <button onClick={redirectToLink} className={`text-white button mt-4`} >{lang.pay}</button>
            }
        </div>
    )
}

export default Pay