import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Trash } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

function DeleteConform({ id, setData, applications, parent_id }) {
    const [show, setShow] = useState(false);
    const lang = useSelector(state => state.lang)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    function handleDelete(id) {

        let deleteUrl = `infos/${id}`;
        if (parent_id === null) deleteUrl = `applications/${id}`
        axios.delete(deleteUrl)
            .then(res => {
                setData({
                    data: applications.data.filter(item => item.id !== id)
                })
                handleClose()
                console.log(res.data);
                toast.error(res.data.data)
                if (parent_id === null) navigate("/")
            })
            .catch(err => {
                handleClose()
                toast.error(err.response.data.error)
            })
    }

    return (
        <>
            {/* <button className='button mt-4' variant="primary" > */}
            <Trash
                color='red'
                onClick={handleShow}
                className='mt-2 cursor-pointer align-self-end '
                size={22} />
            {/* </button> */}
            <Modal
                centered show={show} onHide={handleClose}>
                <Modal.Header style={{ backgroundColor: "#6d47f2" }} closeButton>
                    <Modal.Title style={{
                        color: "#fff",
                        fontSize: "18px"
                    }}>{lang.application.delete}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {lang.application.cancel}
                    </Button>
                    <Button variant="danger" onClick={() => handleDelete(id)}>
                        {lang.application.confirm}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default DeleteConform;