import Login from "../pages/forms/Login";
import Confirm from "../pages/forms/Confirm";
import NotMatch from "../pages/NoMatch";
import Register from "../pages/forms/Register";
import ProtectedRoute from "./protected-route";

import OverView from "../pages/overview";
import News from "../pages/news";
import OneNews from "../pages/news/oneNews";
import { Outlet } from "react-router-dom";
import Settings from "../pages/settings";
import Personal from "../pages/settings/Personal";
import ChangeLang from "../pages/settings/ChangeLang";
import PrivacyAndPolicy from "../pages/settings/PrivacyAndPolicy";
import Application from "../pages/aplication";
import AddApplication from "../pages/aplication/parent";
import AddChild from "../pages/aplication/child";
import PayAndFamily from "../pages/aplication/PayAndFamily";
import Notification from "../pages/notification";
import ChangePassword from "../pages/settings/ChangePassword";
import Example from "../components/Datepicker";
import Pay from "../pages/aplication/pay";
import Home from "../pages/Home";
import Number from "../pages/forms/ResetPassword/Number";
import SetNewPassword from "../pages/forms/ResetPassword/SetNewPassword";

const routesConfig = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot",
    element: <Number />,
  },
  {
    path: "/confirm",
    element: <Confirm />,
  },
  {
    path: "/set-password",
    element: <SetNewPassword />,
  },
  {
    element: <ProtectedRoute />,
    path: "/",
    children: [
      {
        element: <Home />,
        index: true,
      },

      {
        path: "/overview",
        element: <OverView />,
      },
      {
        path: "/over",
        element: <Example />,
      },
      {
        path: "/notification",
        element: <Notification />,
      },
      {
        path: "/application",
        element: <Outlet />,
        children: [
          {
            path: "index",
            element: <Application />,
          },
          {
            path: "own",
            element: <AddApplication />,
          },
          {
            path: "child",
            element: <AddChild />,
          },
          {
            path: "pay-add",
            element: <PayAndFamily />,
          },
          {
            path: "pay",
            element: <Pay />,
          },
        ],
      },
      {
        path: "/news",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <News />,
          },
          {
            path: ":id",
            element: <OneNews />,
          },
        ],
      },
      {
        path: "/settings",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Settings />,
          },
          {
            path: "personal-info",
            element: <Personal />,
          },
          {
            path: "privacy-and-policy",
            element: <PrivacyAndPolicy />,
          },
          {
            path: "change-lang",
            element: <ChangeLang />,
          },
          {
            path: "change-password",
            element: <ChangePassword />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotMatch />,
  },
];

export default routesConfig;
