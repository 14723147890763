import React from 'react'
import useGet from '../../components/httpHook/useGet'
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

function Notification() {
    const [data, loading] = useGet("/notifications");
    const lang = useSelector(state => state.lang);
    return (
        <div className='mx-3 py-3'>
            <h1>{lang.menu.notifications}</h1>
            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        {
                            data?.data?.length === 0 ?
                                <p className='text-center'>{lang.no_notifications}</p> :
                                data?.data?.map(notification => (
                                    <div key={notification.id} className='bor-r16 mb-3 bg-white'>
                                        <h3>{notification.title}</h3>
                                        <p>{notification.body}</p>
                                        <div className='d-flex justify-content-between'>
                                            <span>{notification.date}</span>
                                            <span>{notification.hour}</span>
                                        </div>
                                    </div>
                                ))
                        }
                    </>
                )
            }
        </div>
    )
}

export default Notification