import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useGet from '../../components/httpHook/useGet'
import { useSelector } from 'react-redux';
import "./main.css"
function News() {

    const [data, loading] = useGet("news");

    const lang = useSelector(state => state.lang)

    return (
        <div className='px-3 news'>
            <h1>{lang.menu.news}</h1>
            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>{
                        data?.data?.items.length === 0 ?
                            <p className='text-center'>{lang.no_news}</p> :
                            <Row xs={1} lg={2}>
                                {
                                    data?.data?.items?.map(item => (
                                        <Col key={item.id}>
                                            <Card className='p-3 mb-4 bor-r16 border-0'>
                                                <div><Card.Img className='rounded' variant="top" src={item.file} /></div>
                                                <Card.Body className='mt-3'>
                                                    <Card.Title>{item?.title}</Card.Title>
                                                    <Card.Text className='text-gray'>
                                                        {item?.description.split(" ").slice(0, 10).join(" ")}{"... "}
                                                        <Link className='fw-bold' to={`/news/${item.id}`}>{lang.application.more}</Link>
                                                    </Card.Text>
                                                    <Card.Text className='text-gray m-0'>
                                                        {item.created_at}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                    }
                    </>)
            }
        </div>
    )
}

export default News