export const dotDate = (selectedDate) => {

  const date = new Date(selectedDate);
  let d =
    date.getDate().toLocaleString().length === 1
      ? "0" + date.getDate()
      : date.getDate();
  let m =
    (date.getMonth() + 1).toLocaleString().length === 1
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let y = date.getFullYear();
  return `${d}.${m}.${y}`;
};

export const LineDate = (item) => {
  let [d, m, y] = item.split(".");
  return `${y}-${m}-${d}`;
};
