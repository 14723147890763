import "./App.css";
import RouterRender from "./routes/router-render";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./routes/Auth";
import { CookiesProvider } from "react-cookie";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store";
// set default axios URL
axios.defaults.baseURL = "https://api.greenkard.uz/v1/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <AuthProvider>
          <ToastContainer position="bottom-right" autoClose={3000} />
          <RouterRender />
        </AuthProvider>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
