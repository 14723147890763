import React, { useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../routes/Auth'
import InputFeild from '../../../components/InputFeild/InputFeild'
import { useCookies } from 'react-cookie'

function SetNewPassword() {
    const lang = useSelector(state => state.lang)
    const [loading, setLoading] = useState(false)
    const auth = useAuth();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();


    const passport = watch("password");
    const [cookie, setCookie, removeCookie] = useCookies()



    function onSubmit(data) {
        console.log(auth.conformUser, cookie.userToken, "new password");
        // removeCookie("userToken", { path: "/" })
        setLoading(true)
        axios.post("/set-password", data, {
            headers: {
                Authorization: "Bearer " + cookie.userToken
            }
        })
            .then(res => {

                setCookie("userToken", res.data.data.token, { path: "/" });
                setLoading(false)
                toast.success(lang.settings.succesPasswordChange);
                reset()
                navigate("/")
                // window.location.reload()
            }).catch(err => {
                setLoading(false)
                toast.error(err.response.data.error)
            })
    }

    return (
        <>
            <div className="form-container container">
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
                            <h1 className="title">{lang.login.set_password}</h1>
                            <p className="title-bottom">{lang.login.missed}</p>

                            <InputFeild
                                data={register("password", { required: lang.inputs.password_required })}
                                type={"password"}
                                error={errors.password}
                                placeholder={lang.inputs.new_password} />

                            <InputFeild
                                data={register("password_confirmation", { required: lang.inputs.password_confirmation_required, validate: value => value === passport || lang.inputs.password_confirmation_not_same })}
                                type={"password"}
                                error={errors.password_confirmation}
                                placeholder={lang.inputs.new_password_confirmation} />

                            <p className='text-danger'>{auth.error}</p>

                            <button type="submit" className="button">{loading ? <Spinner className='me-2' animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}{lang.update}</button>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SetNewPassword