import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import useGet from '../../components/httpHook/useGet'
import Status from '../../components/Status/Status'
import { useDispatch, useSelector } from 'react-redux'
import { updateApplication } from '../../store/applications/applicationSlice'
import DeleteConform from '../../components/modals/DeleteConform'

function PayAndFamily() {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const [applications, loading, , setData] = useGet("infos/" + params.get("id"))
    const lang = useSelector(state => state.lang)

    dispatch(updateApplication(applications?.data))
    sessionStorage.setItem("applications", JSON.stringify(applications?.data))

    return (
        <div>
            <div xs={1} xl={2} className='mt-4 mb-5'>
                {
                    loading ? <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> : <Row xs={1} xl={2}>
                        {
                            applications?.data?.map(application => (
                                <Col key={application.id} className="mb-3">
                                    <div className="user-item d-flex border justify-content-between bor-r16">
                                        <Link to={`/application/${application.type === "child" ? "child" : "own"}?id=${application?.id}&parent_id=${application.parent_id}&type=change`} className='d-flex align-items-center'>
                                            <div className="user-image me-2">
                                                <img src={application.file.link} alt='Salom' />
                                            </div>
                                            <div>
                                                <h3>{application.first_name + " " + application.last_name}</h3>
                                                <p className='m-0'>ID: {application.id}</p>
                                            </div>
                                        </Link>
                                        <div className='align-self-end d-flex flex-column ' >
                                            <Status text={application.type} status={"success"} />
                                            <DeleteConform parent_id={application.parent_id} applications={applications} setData={setData} id={application.id} />
                                            {/* <Trash onClick={() => handleDelete(application.id)} color='red' className='mt-2 cursor-pointer align-self-end ' size={22} /> */}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </ Row>
                }
            </ div>
            <Row>
                <Col><Link to={`/application/own?parent_id=${params.get("id")}&type=parent`} className='button mb-3 text-white'>{lang.application.add_spouse}</Link></Col>
                <Col><Link to={`/application/child?parent_id=${params.get("id")}`} className='button mb-3 text-white'>{lang.application.add_kid}</Link></Col>
            </Row>
            {
                !applications?.data?.find(item => item.type === "parent")?.is_paid &&
                (<Row xs={1}>
                    <Col><Link to={`/application/pay?id=${params.get("id")}`} className='button text-white'>{lang.application.pay}</Link></Col>
                </Row>)
            }
        </div>
    )
}

export default PayAndFamily