import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import InputFeild from '../../../components/InputFeild/InputFeild'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { changePhone } from '../../../components/Externel functions/specailHandleChange'
import { useAuth } from '../../../routes/Auth'

function Number() {

    const lang = useSelector(state => state.lang);
    const auth = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            phone: "+998"
        }
    });

    function sendData(data) {
        data = {
            ...data,
            phone: data.phone.replace(/\D/g, '')
        }
        auth.forgotPassword(data)
    }



    return (
        <div className="form-container container">
            <Row className="align-items-center justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <form className="form-box" onSubmit={handleSubmit(data => sendData(data))}>
                        <h1 className="title">{lang.login.reset_password}</h1>
                        <p className="title-bottom">{lang.login.missed}</p>
                        <InputFeild
                            data={register("phone", {
                                required: true, minLength: { value: 17, message: lang.inputs.phone_required },
                                onChange: (e) => e.target.value = changePhone(e.target.value)
                            })}
                            placeholder={lang.inputs.phone}
                            type="text"
                            error={errors?.phone} />
                        <p className='text-danger'>{auth.error}</p>
                        <button type="submit" className="button mt-3">
                            {!!auth.loading ? <Spinner className='me-2' animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            {lang.login.signin}</button>
                        <p className="help-msg mt-3">{lang.login.no_account} <Link to="/register">{lang.login.click}</Link></p>
                    </form>
                </Col>
            </Row>
        </div>
    )
}

export default Number
