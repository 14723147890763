// import { ArrowDown, ArrowUp } from 'iconsax-react'
import React from 'react'
import './main.css'
function Status({ status, text, increase }) {
    return (
        <div className={`status-box ${status}`}>
            {/* {increase === "up" && <ArrowUp size='14' color={increase === "up" ? '#049669' : "#6d47f2"} />}
            {increase === "down" && <ArrowDown size="14" color={increase === "up" ? '#049669' : "#6d47f2"} />} */}
            {text}
        </div>
    )
}

export default Status