import React, { useEffect, useState } from 'react'
import { TickCircle } from 'iconsax-react'
import './main.css'
import { Col, Row, Spinner } from 'react-bootstrap'
import useGet from '../../components/httpHook/useGet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
function Application() {

    const [selectedTatif, setSelectedTatif] = useState("");
    const [data, loading] = useGet("tariffs");
    const lang = useSelector(state => state.lang)
    const tariffElements = useRef()
    function handleChange(e) {
        sessionStorage.setItem("tariff_id", e.target.value);
        setSelectedTatif(e.target.value)
    }

    useEffect(() => {
        setSelectedTatif(data?.data?.items[0].id)
        sessionStorage.setItem("tariff_id", data?.data?.items[0].id);
        const fitstInput = tariffElements.current.querySelectorAll('input')[0];
        if (fitstInput) fitstInput.checked = true
    }, [data])

    return (
        <div>
            <h1>{lang.application.tariffs}</h1>
            {
                loading ? <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                    <Row xs={1} lg={2} ref={tariffElements} className='tariffs'>
                        {data?.data?.items?.map(tariff => (
                            <Col key={tariff.id} className="tariffs-item">
                                <input className='d-none' type="radio" id={tariff.id} onChange={handleChange} name='tariffs' value={tariff.id} />
                                <label htmlFor={tariff.id}>
                                    <div className='tariffs-header'>
                                        {tariff.title}
                                    </div>
                                    <div className="tariffs-body">
                                        <p className="tariffs-title"> <strong>{tariff.price} </strong> uzs</p>
                                        <ul className='p-0 m-0'>
                                            {
                                                tariff.elements.map(item => (
                                                    <li key={item.id}><TickCircle
                                                        size="20"
                                                        color={item.status ? "#6D47F2" : '#7A7A7A'}
                                                        variant="Bold"
                                                        className='me-3'
                                                    /> <span>{item.title}</span></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </label>
                            </Col>
                        ))}
                    </Row>
            }
            {
                !selectedTatif ?
                    <button className={`disabled button mt-4`} >{lang.continue}</button> :
                    <Link to="/application/own" className={`text-white button mt-4`} >{lang.continue}</Link>
            }


        </div>
    )
}

export default Application