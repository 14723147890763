import { EmojiSad } from 'iconsax-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function NoMatch() {
    const lang = useSelector(state => state.lang)
    return (
        <div className='container'>
            <div className="vh-100 d-flex flex-column align-items-center justify-content-around">
                <h4 className='fs-3'>{lang.not_found}</h4>
                <div className='text-center'>
                    <EmojiSad size="266" color="#6d47f2" />
                    <p style={{ fontSize: "120px" }} className=' fw-bold'>404</p>
                </div>
                <Link to="/" className='button w-50 text-white'>{lang.menu.home}</Link>
            </div>
        </div>
    )
}

export default NoMatch