import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useGet(url, label = false) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const lang = useSelector(state => state.lang)
    useEffect(() => {
        async function fetchData() {
            let sendURl = ''
            if (url.includes("?"))
                sendURl = url + "&_lang=" + lang.lang;
            else sendURl = url + "?_lang=" + lang.lang;
            setLoading(true)
            axios.get(sendURl)
                .then(res => {
                    if (label) {
                        const data = res.data.data.map(item => ({ value: item.id, label: item.title }))
                        setData(data)
                    }
                    else
                        setData(res.data)
                })
                .catch(err => setError(err))
                .finally(() => setLoading(false))
        }
        fetchData()
    }, [url, label, lang.lang])

    return [data, loading, error, setData]
}

export default useGet;