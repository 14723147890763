
import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import Status from '../../components/Status/Status'
import './main.css'
import { useSelector } from 'react-redux'
import useGet from '../../components/httpHook/useGet'
function OverView() {
    const lang = useSelector(state => state.lang);
    const [data, loading] = useGet("overview");
    return (
        <div className='mx-3'>
            <h1>{lang.menu.overviews}</h1>
            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <Row xs={1}>
                        <Col className='mb-3'>
                            <div className="bg-white bor-r16">
                                <h3>{lang.menu.overviews}</h3>
                                <p className='overview-number mb-1'>{data?.data?.amount === null ? 0 : Math.trunc(data?.data?.amount / 100)}</p>
                                <div className='d-flex justify-content-between' >
                                    <span className='text-gray'>vs. {data?.data?.months} month</span>
                                    <Status status={"waiting"} text={data?.data?.applications} increase={"down"} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }
        </div >
    )
}

export default OverView