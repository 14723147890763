export const changePhone = (value) => {
  // Remove all non-numeric characters from the input string
  const input = value.replace(/\D/g, "");
  let formattedInput = "+998 ";
  // Format the input string
  for (let i = 3; i < input.length && i < 12; i++) {
    if (i === 5 || i === 8 || i === 10) {
      formattedInput += " ";
    }
    formattedInput += input[i];
  }
  return formattedInput;
};

export const changePassport = (value) => {
  let input;
  if (value.length <= 2) input = value.replace(/[^A-Z,a-z]/g, "").toUpperCase();
  else if (value.length >= 3 && value.length <= 10) {
    let [serial, number] = [value.slice(0, 2), value.slice(2, value.length)];
    input = serial + " " + number.replace(/\D*/g, "");
  } else input = value.slice(0, value.length - 1);
  return input;
};

export const changePromo = (value) => {
  let input;
  if (value.length <= 4) input = value.replace(/[^A-Z,a-z]/g, "").toUpperCase();
  else if (value.length >= 3 && value.length <= 10) {
    let [serial, number] = [value.slice(0, 2), value.slice(2, value.length)];
    input = serial + " " + number.replace(/\D*/g, "");
  } else input = value.slice(0, value.length - 1);
  return input;
};
