import React, { useState } from 'react'
import "./main.css"
import { Eye, EyeSlash, Gallery, GalleryAdd } from 'iconsax-react'
function InputFeild({ data, type, value, placeholder, error }) {

    const [feildType, setFeildType] = useState("password");
    function handleEyeChange() {
        setFeildType((type) => type === 'text' ? 'password' : 'text')
    }
    function whichField() {
        switch (type) {
            case "file":
                {
                    return (
                        <label className="button input-feild">
                            <GalleryAdd size={26} color='#fff' />
                            <span className='text-white'>{value ? value.name : placeholder} </span>
                            <input
                                accept='image/*'
                                {...data}
                                className="d-none"
                                type={type} />
                        </label>
                    )
                }

            case "textarea":
                {
                    return (
                        <label className='w-100' >
                            <textarea placeholder={placeholder} className='input-feild' {...data} />
                        </label>
                    )
                }

            case "password": return (
                <>
                    <input
                        {...data}
                        className="input-feild"
                        type={feildType}
                        placeholder={placeholder}
                    />
                    <span onClick={handleEyeChange} className='eye'>{feildType === "text" ? <EyeSlash size="20" color="#6d47f2" /> : <Eye size="20" color="#6d47f2" />}</span>
                </>
            )
            default: return (
                <input
                    {...data}
                    className="input-feild"
                    type={type}
                    placeholder={placeholder}
                />
            )
        }
    }

    return (
        <div className='input-fields'>
            {whichField()}
            {error?.message && <span className='error-text text-danger'>{error?.message}</span>}
        </div>
    )

}

export default InputFeild