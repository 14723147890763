import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify';
import parser from 'html-react-parser'
import './main.css'
import { Spinner } from 'react-bootstrap';
function PrivacyAndPolicy() {
    const [priAndPol, setPriAndPol] = useState({});
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        axios.get("privacy-and-policy?_lang=uz").then(res => {
            setLoading(false)
            setPriAndPol(res.data.data)
        })
    }, [])
    return (
        <div className='privacy py-5 px-2'>
            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <h1>
                            {priAndPol?.title}
                        </h1>
                        <div>
                            {parser(DOMPurify.sanitize(priAndPol?.content))}
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default PrivacyAndPolicy