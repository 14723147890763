import { Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import InputFeild from '../../components/InputFeild/InputFeild'
import { useAuth } from '../../routes/Auth'
import { useSelector } from 'react-redux'
import { changePhone } from '../../components/Externel functions/specailHandleChange'
import { useForm } from "react-hook-form"

function Register() {
    const lang = useSelector(state => state.lang)
    const auth = useAuth();
    const registerFunc = auth.register;

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            first_name: "",
            phone: "+998",
            password: ""
        }
    })

    function submitData(data) {
        data = {
            ...data,
            phone: data.phone.replace(/\D/g, '')
        }
        registerFunc(data)
    }

    return (
        <div className="form-container container">
            <Row className="align-items-center justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <form onSubmit={handleSubmit(submitData)} className="form-box">
                        <h1 className="title">{lang.login.register}</h1>
                        <p className="title-bottom">{lang.login.create}</p>
                        <InputFeild
                            data={register("first_name", { required: lang.inputs.fname_required })}
                            placeholder={lang.inputs.fname}
                            error={errors.first_name}
                            type="text" name={"first_name"} />

                        <InputFeild
                            data={register("phone", { required: true, minLength: { value: 17, message: lang.inputs.phone_required }, onChange: (e) => e.target.value = changePhone(e.target.value) })}
                            placeholder={lang.inputs.phone}
                            type="text"
                            error={errors?.phone} />

                        <InputFeild
                            error={errors?.password}
                            data={register("password", { required: lang.inputs.password_required, minLength: { value: 6, message: lang.inputs.password_length_required } })}
                            placeholder={lang.inputs.password}
                            type="password" name={"password"} />

                        <p className="text-danger">{auth.error}</p>
                        <button className="button mt-3">
                            {!!auth.loading ? <Spinner className='me-2' animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            {lang.login.register}
                        </button>
                        <p className="help-msg mt-3">{lang.login.have_account} <Link to="/login">{lang.login.click}</Link></p>
                    </form>
                </Col>
            </Row>
        </div>
    )
}

export default Register