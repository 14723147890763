import axios from "axios";
import { useState, createContext, useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [cookie, setCookie, removeCookie] = useCookies();

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({

  })
  // keep user auth tepmrorary token state
  const [uuid_key, setUuid_key] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // User auth token state
  const [conformUser, setConformUser] = useState(() => {
    let userConform = localStorage.getItem('userToken');
    if (userConform) {
      return localStorage.getItem('userToken');
    }
    return null;
  });

  // Login and get tepm uuid key
  const login = (user) => {
    if (!!user) setUserInfo(user);
    setLoading(true);
    axios
      .post("login", user ? user : userInfo)
      .then((res) => {
        setUuid_key(res.data.data.uuid_key);
        if (user) navigate("/confirm");
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.error);
        setLoading(false);
      });
  };

  // Register and get uuid key
  const register = (user) => {
    if (!!user) setUserInfo(user);
    setLoading(true);
    axios
      .post("register", user ? user : userInfo)
      .then((res) => {
        setUuid_key(res.data.data.uuid_key);
        if (user) navigate("/confirm");
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.error);
        setLoading(false);
      });
  };

  // forgot password
  const forgotPassword = (phone) => {

    if (!!phone) setUserInfo(phone);

    setLoading(true);
    axios
      .post("forgot-password", phone ? phone : userInfo)
      .then((res) => {
        setUuid_key(res.data.data.uuid_key);
        if (phone) navigate("/confirm");
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  // get user token
  const conform = (confNumber, redirectLocation) => {
    if (!!uuid_key) {
      setLoading(true);
      axios
        .post("auth", {
          uuid_key: uuid_key,
          code: confNumber,
        })
        .then((res) => {
          localStorage.setItem('userToken', res.data.data.token)
          setConformUser(res.data.data.token);
          setLoading(false);
          setError("");
          navigate("/")
          window.location.href = '/'

        })
        .catch((err) => {
          setLoading(false);
          setError(err?.response?.data?.error);
        });
    } else {
      navigate("/login");
    }
  };

  const loginWithTelegram = (result) => {

    axios.post('/bot-login', {
      init_data: result
    }).then(res => {
      localStorage.setItem('userToken', res?.data?.data?.token)
      navigate("/")
      window.location.href = '/'
    })

  }



  // Logout user
  const logOut = () => {
    localStorage.removeItem('userToken')
    setConformUser(false);
    navigate("login");
    setUuid_key(null);
  };

  return (
    <AuthContext.Provider
      value={{
        tempToken: uuid_key,
        conformUser,
        error,
        loginWithTelegram,
        loading,
        userInfo,
        login,
        register,
        conform,
        forgotPassword,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};




export const useAuth = () => {
  return useContext(AuthContext);
};
