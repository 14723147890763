import { Calendar } from "iconsax-react";

function CustomMultipleInput(props) {
    const { onFocus, value, onChange, placeholder } = props
    return (
        <div tabIndex={100} onFocus={onFocus} className='input-feild d-flex align-items-center'>
            <Calendar size="26" color="#7A7A7A" />
            <input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className='border-0 ms-2'
            />
        </div>
    )
}

export default CustomMultipleInput;