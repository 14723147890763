import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify';
import { Spinner } from 'react-bootstrap';
import useGet from '../../components/httpHook/useGet';
import { useSelector } from 'react-redux';
function OneNews() {
    let { id } = useParams();
    const lang = useSelector(state => state.lang)
    const [data, loading] = useGet(`/news/${id}`);

    return (
        <div className='mx-3 py-3 inner-body'>
            <h1>{lang.menu.news}</h1>
            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <div className='news-image text-center'>
                            <img className='w-50  rounded' src={data?.data?.file} alt="card" />
                        </div>

                        <h3 className='mt-4'>{data?.data?.title}</h3>
                        <p className='text-justify'> {parse(DOMPurify.sanitize(data?.data?.content))}</p>
                    </>
                )
            }
        </div>
    )
}

export default OneNews