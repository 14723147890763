import React, { useState, useRef, useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../routes/Auth';
import { useSelector } from 'react-redux';
import MyTimer from '../../components/Timer';

function Confirm() {
    const lang = useSelector(state => state.lang)
    const navigate = useNavigate()
    const initialState = {
        f1: "",
        f2: "",
        f3: "",
        f4: "",
    };

    const [conformNumber, setConformNumber] = useState(initialState)
    const auth = useAuth();
    const conformFunc = auth.conform;
    const location = useLocation()
    const redirectLocation = location.state?.path || "/";

    useEffect(() => {
        if (auth.conformUser) {
            navigate(redirectLocation)
        }
        if (!auth.tempToken) {
            navigate("/login")
        }
    })

    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();

    function handleChange(e) {
        setConformNumber(() => {
            return {
                ...conformNumber,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        if (ref1.current.value.length === 1) {
            ref1.current.classList.remove("error-box")
            ref1.current.classList.add("focus-active")
            ref2.current.focus()
        } else {
            ref1.current.classList.remove("focus-active")
        }
        if (ref2.current.value.length === 1) {
            ref2.current.classList.remove("error-box")
            ref2.current.classList.add("focus-active")
            ref3.current.focus()
        } else {
            ref2.current.classList.remove("focus-active")
        }
        if (ref3.current.value.length === 1) {
            ref3.current.classList.remove("error-box")
            ref3.current.classList.add("focus-active")
            ref4.current.focus()
        } else {
            ref3.current.classList.remove("focus-active")
        }
        if (ref4.current.value.length === 1) {
            ref4.current.classList.remove("error-box")
            ref4.current.classList.add("focus-active")
        } else {
            ref4.current.classList.remove("focus-active")
        }
    }, [conformNumber])

    function handleSubmit(e) {
        e.preventDefault()
        let number = Object.values(conformNumber).join("")
        if (number.length === 4) {
            console.log(1);
            conformFunc(number)
        }
        if (auth.error.length !== 0) {
            ref1.current.classList.add("error-box")
            ref2.current.classList.add("error-box")
            ref3.current.classList.add("error-box")
            ref4.current.classList.add("error-box")
        }
    }

    return (
        <div className="form-container container">
            <Row className="align-items-center justify-content-center">
                <Col xs={12} md={8} lg={5}>
                    <form onSubmit={handleSubmit} className="form-box">
                        <h1 className="title">{lang.login.confromation}</h1>
                        <p className="title-bottom">{lang.login.message}</p>
                        <Row>
                            <Col>
                                <input maxLength={1} className="input-feild" ref={ref1} type="text" name={"f1"} value={conformNumber.f1} onChange={e => handleChange(e)} />
                            </Col>
                            <Col>
                                <input maxLength={1} className="input-feild" ref={ref2} type="text" name={"f2"} value={conformNumber.f2} onChange={e => handleChange(e)} />
                            </Col>
                            <Col>
                                <input maxLength={1} className="input-feild" ref={ref3} type="text" name={"f3"} value={conformNumber.f3} onChange={e => handleChange(e)} />
                            </Col>
                            <Col>
                                <input maxLength={1} className="input-feild" ref={ref4} type="text" name={"f4"} value={conformNumber.f4} onChange={e => handleChange(e)} />
                            </Col>
                        </Row>
                        <p className='text-danger'>{auth.error}</p>
                        <p className="help-msg"><MyTimer /></p>

                        <button type='submit' className="button mt-3">
                            {!!auth.loading ? <Spinner className='me-2' animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            {lang.login.signin} </button>
                    </form>
                </Col>
            </Row>

        </div>
    )
}

export default Confirm