import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../routes/Auth';
import { useSelector } from 'react-redux';

function MyTimer() {
  const loadingTime = 60;
  const [seconds, setSeconds] = useState(loadingTime);
  const [recall, setReCall] = useState(false);
  const auth = useAuth();
  const lang = useSelector(state => state.lang)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => {
        if (seconds === 1) {
          clearInterval(interval)
          setReCall(true)
        }
        return seconds - 1
      }
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);




  function restartCal() {
    if (Object.keys(auth.userInfo).length === 1) {
      auth.forgotPassword()
    } else if (auth.userInfo.first_name) {
      auth.register()
    } else {
      auth.login();
    }
    setReCall(false);
    setSeconds(loadingTime);
  }
  return (
    <>
      {recall ? <button className='get-code-button' type='button' onClick={() => restartCal()}>{lang.login.get_code}</button> : <span>{lang.login.resend} {seconds}</span>}
    </>
  );
}

export default MyTimer;
