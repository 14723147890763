import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Activity, ArrowLeft, Direct, Home, Logout, Notification, Printer, Setting } from 'iconsax-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../routes/Auth'
import './main.css'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../store/user/userSlice'

function Header({ inner }) {
    const auth = useAuth();
    const clearLogout = auth.logOut;
    const lang = useSelector(state => state.lang)

    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const [query] = useSearchParams()
    const documentId = query.get("id")


    function handlePrint() {
        axios.get(`/download-contract/${documentId}`,
            {
                responseType: 'blob',
            }
        ).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    function logOut() {
        clearLogout()
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (!user.lname)
            axios.get("me")
                .then(res => {
                    dispatch(updateUser({
                        fname: res.data.data.first_name,
                        lname: res.data.data.last_name,
                        image: res.data.data.image,
                        phone: res.data.data.phone
                    }))
                })
    }, [dispatch, user.lname])

    return (
        <>
            {!inner ?
                <Navbar bg="white" expand="lg">
                    <Container>
                        <LinkContainer to="/">
                            <Navbar.Brand className='d-flex align-items-center' href="/">
                                <div className='user-image me-2'>
                                    {!!user.image ?
                                        <img src={user.image} alt="user" />
                                        : <span>{user.fname[0]}</span>

                                    }
                                </div>
                                <div className='user-name'>
                                    <p>{lang.welcome}</p>
                                    <h3>{`${user.fname} ${user.lname}`}</h3>
                                </div>
                            </Navbar.Brand></LinkContainer>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <LinkContainer to="/"><Nav.Link className='align-items-center'>
                                    <Home
                                        className='me-2'
                                        size="24"
                                        color={"#7A7A7A"}
                                    />
                                    <span>{lang.menu.home}</span>
                                </Nav.Link></LinkContainer>
                                <LinkContainer to="overview"><Nav.Link>
                                    <Activity
                                        size="24"
                                        className='me-2'
                                        color="#7A7A7A"
                                    />
                                    {lang.menu.overviews}</Nav.Link></LinkContainer>
                                <LinkContainer to="news"><Nav.Link>
                                    <Direct className='me-2' size="24" color="#7A7A7A" />
                                    {lang.menu.news}</Nav.Link></LinkContainer>
                                <LinkContainer to="settings"><Nav.Link>
                                    <Setting className='me-2' size="24" color="#7a7a7a" />{lang.menu.settings}</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="notification"><Nav.Link>
                                    <Notification className='me-2' size="24" color="#7a7a7a" />{lang.menu.notifications}</Nav.Link>
                                </LinkContainer>
                                <button onClick={logOut} className='logout ms-4' to="notification">
                                    <Logout className='me-2' size="24" color="#fff" />
                                    {lang.menu.logOut}
                                </button>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar > :
                <header className='container inner-header d-flex justify-content-between'>
                    <div className='d-flex align-items-center' onClick={() => navigate(-1)}>
                        <ArrowLeft size="24" color="#333" />
                        <h3 className='mb-0 ms-2'>{lang.back}</h3>
                    </div>
                    {
                        documentId && (
                            <div>
                                <button className='btn' onClick={handlePrint}> <Printer size="32" color="#6d47f2" /></button>
                            </div>

                        )
                    }
                </header>
            }

        </>

    )
}

export default Header