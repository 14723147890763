import React from 'react'
import useGet from '../../components/httpHook/useGet'
import { Col, Row, Spinner } from 'react-bootstrap'
import Status from '../../components/Status/Status'
import { Link } from 'react-router-dom'

function Free({ empty }) {
    const [applications, loading] = useGet("applications?type=free")

    return (
        <div xs={1} xl={2} className='mt-4 mb-5'>
            {
                loading ? <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                    applications?.data?.items.length === 0 ? (
                        <p className='text-center'>{empty}</p>
                    ) :
                        <Row xs={1} xl={2}>
                            {

                                applications?.data?.items.map(application => (
                                    <Col key={application.id} className="mb-3">
                                        <Link to={`/application/pay-add?id=${application.parent.id}`} className="user-item d-flex justify-content-between bor-r16">
                                            <div className='d-flex align-items-center'>
                                                <div className="user-image me-2">
                                                    <img src={application.parent.file_id} alt='img' />
                                                </div>
                                                <div>
                                                    <h3>{application.parent.short_info}</h3>
                                                    <p className='m-0'>ID: {application.id}</p>
                                                </div>
                                            </div>
                                            <div className='align-self-end '>
                                                <Status text={application.status} status={"success"} />
                                            </div>
                                        </Link>
                                    </Col>
                                ))
                            }
                        </ Row>
            }
        </ div>
    )
}

export default Free