import { GalleryAdd } from 'iconsax-react'
import React, { useState } from 'react'
import './main.css'
import { Col, Row, Spinner } from 'react-bootstrap';
import InputFeild from '../../components/InputFeild/InputFeild';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../store/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
function Personal() {
    // const [img, setImg] = useState("");
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, reset, watch, formState: { errors }, handleSubmit } = useForm()

    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        // birthDate: "",
        // phone: ""
    });

    const img = watch("file")

    function onSubmit(data) {
        console.log(data);
        const formData = new FormData();
        formData.append("file", data.file[0])
        setLoading(true)

        axios.post("file", formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        })
            .then(res => {
                const updateProfile = {
                    ...data,
                    file_id: res.data.data.id
                }
                dispatch(updateUser({ image: res.data.data.link }))

                axios.patch("update-profile", updateProfile)
                    .then(res => {
                        setLoading(false);
                        reset()
                        dispatch(updateUser({ fname: res.data.data.first_name, lname: res.data.data.last_name }))
                        navigate(-1)
                    })
            })
            .catch(err => console.log(err))
    }
    return (
        <div className='personal-info'>
            <h1>{lang.settings.personal}</h1>
            <form name='updateProfile' onSubmit={handleSubmit(onSubmit)}>
                <div className='d-flex align-items-center'>
                    <div className='no-image'>
                        {img?.length ? <img src={URL.createObjectURL(img[0])} alt="profile img" /> :
                            <GalleryAdd variant="Bold" size="28" color="#6D47F2" />}
                    </div>
                    <label htmlFor="profile-img">
                        <p className='text-gray'>{lang.settings.image}<br /> .jpeg, png jpg</p>
                        <b>{lang.upload}</b>
                    </label>
                    <input {...register("file", { required: lang.inputs.image_required })} className='d-none' id='profile-img' type="file" accept='image/png, image/jpg, image/jpeg' />
                </div>
                {errors?.file?.message ? <span className='d-block text-danger'>{errors?.file?.message}</span> : ""}

                <Row className='mt-4' xs={1} lg={2}>
                    <Col>
                        <InputFeild data={register("first_name", { required: lang.inputs.fname_required })} error={errors.first_name} placeholder={lang.inputs.fname} />
                    </Col>
                    <Col>
                        <InputFeild data={register("last_name", { required: lang.inputs.sname_required })} error={errors.last_name} placeholder={lang.inputs.sname} />
                    </Col>
                </Row>
                <Col xs={12}>
                    <button type="submit" className="button">{loading ? <Spinner className='me-2' animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : ""}{lang.update}</button>
                </Col>
            </form>
        </div>
    )
}

export default Personal