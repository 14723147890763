import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    fname: "",
    lname: "",
    image: "",
    phone: "",
  },
  reducers: {
    updateUser: (state, action) => {
      if (action.payload.fname) state.fname = action.payload.fname;
      if (action.payload.lname) state.lname = action.payload.lname;
      if (action.payload.image) state.image = action.payload.image;
      if (action.payload.phone) state.phone = action.payload.phone;
    },
  },
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
