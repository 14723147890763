import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import InputFeild from '../InputFeild/InputFeild';
import axios from 'axios';
import { toast } from 'react-toastify';

function PromoCode() {
    const [show, setShow] = useState(false);
    const lang = useSelector(state => state.lang)
    const handleClose = () => {
        reset()
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const { register, reset, handleSubmit, formState: { errors } } = useForm()

    function onSubmit(data) {
        axios.post("promo-code", data)
            .then(res => {
                handleClose();
                toast.success("Promo code ro'yxatda o'tdi");

            })
            .catch(err => {
                handleClose();
                toast.error(err.response.data.error)
            })
    }
    return (
        <>
            <button className='button mt-4' variant="primary" onClick={handleShow}>
                {lang.application.promo_code}
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{lang.application.promo_header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} name="promo">
                        <InputFeild
                            error={errors.code}
                            data={register("code", { required: lang.application.code_required })}
                            placeholder={lang.application.promo} />
                        <button className='button' >
                            {lang.application.submit}
                        </button>
                    </form >
                </Modal.Body>
            </Modal >
        </>
    );
}

export default PromoCode;