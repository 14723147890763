import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { WalletMoney } from 'iconsax-react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

function PayFromWallet() {
    const [show, setShow] = useState(false);
    const lang = useSelector(state => state.lang)
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);
    const [query] = useSearchParams()
    const navigate = useNavigate()
    function onSubmit() {
        console.log(query.get("id"));
        axios.post("payment/pay-from-wallet", { "info_id": query.get("id") })
            .then(res => {
                handleClose();
                toast.success("To'lov amalga oshirildi");
                navigate(-1)
            })
            .catch(err => {
                handleClose();
                toast.error(err.response.data.error)
            })
    }
    return (
        <>
            <button className='button mt-4' variant="primary" onClick={handleShow}>
                <WalletMoney className='me-2' size="32" color="#fff" />{lang.application.pay_from_wallet}
            </button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{lang.application.pay_wallet_check}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <button onClick={handleClose} type='button' className='button cancel-btn'>{lang.application.cancel}</button>
                        </Col>
                        <Col>
                            <button onClick={onSubmit} className='button'>
                                {lang.application.submit}
                            </button></Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default PayFromWallet;