import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/userSlice";
import langSlice from "./lang/langSlice";
import applicationSlice from "./applications/applicationSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    lang: langSlice,
    applications: applicationSlice,
  },
});
