import React, { useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import InputFeild from '../../components/InputFeild/InputFeild'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';

function ChangePassword() {
    const lang = useSelector(state => state.lang)
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();


    const passport = watch("password");



    function submitData(data) {
        setLoading(true)
        axios.patch("/change-password", data)
            .then(res => {
                setLoading(false)
                toast.success(lang.settings.succesPasswordChange);
                reset()
            }).catch(err => {
                setLoading(false)
                toast.error(err.response.data.error)
            })
    }

    return (
        <div className='personal-info'>
            <h1>{lang.settings.change_password}</h1>
            <form name='changeLang' onSubmit={handleSubmit((data) => submitData(data))}>
                <Row className='mt-4 ' xs={1} lg={2}>
                    <Col>
                        <InputFeild
                            data={register("old_password", { required: lang.inputs.old_password_required })}
                            type={"password"}
                            placeholder={lang.inputs.old_password}
                            error={errors.old_password}
                        />
                    </Col>
                    <Col>
                        <InputFeild
                            data={register("password", { required: lang.inputs.password_required })}
                            type={"password"}
                            error={errors.password}
                            placeholder={lang.inputs.password} />
                    </Col>
                    <Col>
                        <InputFeild
                            data={register("password_confirmation", { required: lang.inputs.password_confirmation_required, validate: value => value === passport || lang.inputs.password_confirmation_not_same })}
                            type={"password"}
                            error={errors.password_confirmation}
                            placeholder={lang.inputs.password_confirmation} />

                    </Col>
                </Row>
                <Col xs={12}>
                    <button type="submit" className="button">{loading ? <Spinner className='me-2' animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : ""}{lang.update}</button>
                </Col>
            </form>
        </div>
    )
}

export default ChangePassword