import React, { useEffect, useState } from 'react'
import InputFeild from '../../components/InputFeild/InputFeild';
import { Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import useGet from '../../components/httpHook/useGet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dotDate } from '../../components/Externel functions/dotDate';
import { useSelector } from 'react-redux';
import { gender } from '../../components/Externel functions/constants';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-multi-date-picker';
import transition from "react-element-popper/animations/transition"
import CustomMultipleInput from '../../components/Datepicker';
import UploadImage from '../../components/modals/imageModal';

function AddChild() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [regions, setRegions] = useState([]);
    const [params] = useSearchParams();
    const lang = useSelector(state => state.lang)
    // const application = useSelector(state => state.applications.find(item => item.id === +params.get("id")));
    let application = []
    if (sessionStorage.getItem("applications") !== "undefined") {
        const decodeStorageData = JSON.parse(sessionStorage.getItem("applications"));
        application = decodeStorageData.find(item => item.id === +params.get("id"));
    }

    const [countries] = useGet("countries?_lang=uz", true);

    const [imageId, setImageId] = useState(application?.file?.id);
    const [user] = useState({
        parent_id: params.get("parent_id"),
        type: "child",
        first_name: application?.first_name ? application.first_name : "",
        last_name: application?.last_name ? application.last_name : "",
        birth_date: application?.birth_date ? application.birth_date : "",
        gender: (application?.gender || application?.gender === 0) ? application?.gender : '',
        birth_country_id: application?.birth_country?.id ? application.birth_country.id : '',
        birth_region_id: application?.birth_region?.id ? application.birth_region.id : '',
        file: application?.file ? application?.file : "",
        tariff_id: sessionStorage.getItem("tariff_id")
    })


    const { register, formState: { errors }, reset, handleSubmit, watch, control } = useForm({
        defaultValues: user
    })
    const img_url = watch("file")

    // Foydalanuchi tug'ulgan davlatini tanalaganda viloyatlarni yuklab olish
    function getBirthRegions(countryId) {
        axios.get(`countries?parent_id=${countryId}&_lang=uz`).then(res => {
            setRegions(res.data.data.map(item => ({ value: item.id, label: item.title })))
        })
    }

    // O'zgaritish uchun kirilganda tug'ulgan davlatini viloyatlarni yuklab olish
    useEffect(() => {
        if (user.birth_country_id !== "") {
            axios.get(`countries?parent_id=${user.birth_country_id}&_lang=uz`).then(res => {
                setRegions(res.data.data.map(item => ({ value: item.id, label: item.title })))
            })
        }
    }, [user.birth_country_id])






    function onSubmit(data) {
        // e.preventDefault();
        setLoading(true)
        const submitData = {
            ...data,
            file_id: imageId,
            birth_date: typeof data.birth_date === "string" ? data.birth_date : dotDate(data.birth_date),
        }

        delete submitData.file

        if (params.get("type") === "change") {
            axios.patch(`infos/${params.get("id")}`, submitData)
                .then(res => {
                    res.data.data.parent_id !== null ?
                        navigate("/application/pay-add?id=" + res.data.data.parent_id, { replace: true }) :
                        navigate("/application/pay-add?id=" + res.data.data.id, { replace: true })

                })
        } else {
            axios.post("infos", submitData)
                .then(res => {
                    setLoading(false)
                    res.data.data.parent_id !== null ?
                        navigate("/application/pay-add?id=" + res.data.data.parent_id, { replace: true }) :
                        navigate("/application/pay-add?id=" + res.data.data.id, { replace: true })
                })
                .catch(err => {
                    setLoading(false)
                    setError(err.response.data.error)
                })
        }
    }

    return (
        <div>
            <h2>{lang.application.add_child}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row xs={1} lg={2}>
                    <Col>
                        {/* Firstname  */}
                        <InputFeild
                            placeholder={lang.inputs.fname}
                            data={register("first_name", { required: lang.inputs.fname_required })}
                            error={errors.first_name} />
                        {/* Lastname */}
                        <InputFeild
                            placeholder={lang.inputs.sname}
                            data={register("last_name", { required: lang.inputs.sname_required })}
                            error={errors.last_name} />
                        {/* Gender Select */}
                        <div className="input-fields">
                            <Controller
                                name='gender'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.gender}
                                        className="custom-select"
                                        options={gender[lang.lang]}
                                        onChange={(val) => onChange(val.value)}
                                        value={value === undefined ? gender[lang.lang]?.find(item => +item.value === +user.gender) : gender[lang.lang]?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.gender_required }}
                            />
                            {errors?.gender?.message && <span className='select-error-text text-danger'>{errors?.gender?.message}</span>}
                        </div>
                    </Col>
                    <Col>
                        {/* Data of birth */}
                        <div className="input-fields">
                            <Controller
                                name='birth_date'
                                rules={{ required: lang.inputs.birthday_required }}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        animations={[
                                            transition()
                                        ]}
                                        render={<CustomMultipleInput placeholder={lang.inputs.birthday} />}
                                        onChange={(date) => onChange(date)}
                                        headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                                        value={value}
                                        format="DD.MM.YYYY"
                                    />
                                )}
                            />
                            {errors?.birth_date?.message && <span className='select-error-text text-danger'>{errors?.birth_date?.message}</span>}
                        </div>
                        {/* Image upload modal */}
                        <div className="input-fields">
                            <UploadImage setImageId={setImageId} imageId={imageId} file={img_url} register={register} />
                            {errors?.file?.message && <span className='select-error-text bottom-0 text-danger'>{errors?.file?.message}</span>}
                        </div>
                        {/* Birth country Select */}
                        <div className="input-fields">
                            <Controller
                                name='birth_country_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.birth_country}
                                        className="custom-select"
                                        options={countries}
                                        onChange={(val) => {
                                            onChange(val.value);
                                            reset((formData) => ({
                                                ...formData,
                                                birth_region_id: "",
                                            }))
                                            getBirthRegions(val.value)
                                        }}
                                        value={!value ? countries?.find(item => +item.value === +user.birth_country_id) : countries?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.birth_country_required }}
                            />
                            {errors?.birth_country_id?.message && <span className='select-error-text text-danger'>{errors?.birth_country_id?.message}</span>}
                        </div>
                        {/* birth regions Select */}
                        <div className="input-fields">
                            <Controller
                                name='birth_region_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.birth_region}
                                        className="custom-select"
                                        options={regions}
                                        noOptionsMessage={() => lang.inputs.no_country}
                                        onChange={(val) => onChange(val.value)}
                                        value={value === "" ? null : user?.birth_region_id ? regions?.find(item => +item.value === +user.birth_region_id) : regions?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.birth_region }}
                            />
                            {errors?.birth_region_id?.message && <span className='select-error-text text-danger'>{errors?.birth_region_id?.message}</span>}
                        </div>
                    </Col>
                </Row>
                <p className='text-danger'>{error}</p>
                <button type='submit' className='button'>
                    {loading ? <Spinner className='me-2' animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : ""}
                    {lang.continue}</button>
            </form>
        </div>
    )
}

export default AddChild