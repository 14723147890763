import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Paid from './paid';
import './main.css'
import Free from './Free';
import All from './all';
import { useSelector } from 'react-redux';
function Home() {
    const [key, setKey] = useState('all');
    const lang = useSelector(state => state.lang);
    sessionStorage.setItem("applications", JSON.stringify([]))
    return (
        <div className='mx-3'>
            <h1>{lang.menu.home}</h1>
            <Tabs
                id="controlled-tab-example"
                defaultActiveKey="profile"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                fill
            >
                <Tab eventKey="all" title={lang.home.menu.all}>
                    <All empty={lang.application.empty} />
                </Tab>
                <Tab eventKey="free" title={lang.home.menu.free}>
                    <Free empty={lang.application.empty} />
                </Tab>
                <Tab eventKey="paid" title={lang.home.menu.paid}>
                    <Paid empty={lang.application.empty} />
                </Tab>
            </Tabs>
            <Link to="/application/index" className="button text-white">{lang.home.add}</Link>
        </div>
    )
}

export default Home