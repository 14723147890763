import { createSlice } from "@reduxjs/toolkit";

const initalState = {
  en: {
    "lang": "en",
    "pay": "Pay",
    "login": {
      "welcome": "Welcome Back",
      "missed": "You have been missed",
      "signin": "Sign in",
      "no_account": "Don't have an account",
      "register": "Register",
      "create": "Create new account to get started",
      "have_account": "Already have account?",
      "click": "Click here",
      "confromation": "Confirmation",
      "message": "We have send code to your phone. Confirm it",
      "resend": "Resend code after",
      "get_code": "Didn't receive code?",
      "reset_password": "Forgot your password",
    },
    "welcome": "Welcome",
    "continue": "Continue",
    "upload": "Upload",
    "update": "Update",
    "no_news": "No news",
    "no_notifications": "No notifications",
    "back": "Back",
    "menu": {
      "home": "Home",
      "overviews": "Overviews",
      "news": "News",
      "settings": "Settings",
      "notifications": "Notifications",
      "logOut": "Logout"
    },
    "application": {
      "tariffs": "Tariffs",
      "add_child": "Add child",
      "add": "Add application",
      "payment": "Payment",
      "pay": "Pay",
      "add_kid": "Add kid",
      "add_spouse": "Add spouse",
      "total_payment": "Total payment",
      "promo_code": "Add promo code",
      "promo": "Enter pormo code",
      "promo_header": "Add promo code",
      "pay_from_click": "Payment from Click UP",
      "pay_from_payme": "Payment from Payme",
      "pay_from_wallet": "Pay from wallet",
      "pay_wallet_check": "Do you really pay out of wallet?",
      "total": "Total",
      "wallet": "Wallet",
      "code_required": "Promo code is required",
      "delete": "Do you want to delete?",
      "cancel": "Cancel",
      "confirm": "Delete",
      "submit": "Submit",
      "upload_image": "Upload image",
      "rule": "Short rules for uploading photos",
      "rule_text": "NOTE: Do not upload photos taken at home. The picture must be taken in a photo salon. <br />\n The maximum size of the uploaded photo should not exceed 240 KB. <br />\n Photo width should be at least 600x600 pixels or 5x5 cm. <br />\n Photo background should be white and clear.",
      "save_image": "Save image",
      "more": "More",
      "empty": "Opps empty",
    },
    "not_found": "Opps page not found",
    "home": {
      "menu": {
        "all": "All",
        "free": "Unpaid",
        "paid": "Paid"
      },
      "add": "Add New"
    },
    "inputs": {
      "fname": "Name",
      "fname_required": "Name is required",
      "sname": "Surname",
      "sname_required": "Surname is required",
      "birthday": "Date of birth",
      "birthday_required": "Date of birth is required",
      "gender": "Gender",
      "gender_required": "Gender is required",
      "live_country": "Live contry",
      "live_country_required": "Live contry is required",
      "live_region": "Live region",
      "live_region_required": "Live region is required",
      "live_district": "Live district",
      "live_district_required": "Live district is required",
      "passport": "Passport number",
      "passport_required": "Passport number is required",
      "passport_exp": "Passport expire date",
      "passport_exp_required": "Passport expire date id required",
      "photo": "Your photo",
      "birth_country": "Country of birth",
      "birth_country_required": "Country of birth is required",
      "birth_region": "Region of birth",
      "birth_region_required": "Region of birth is required",
      "no_country": "Please select your country first",
      "no_region": "Please select your region first",
      "passport_given": "Passport given counrtyt",
      "passport_given_required": "Passport given counrty is required",
      "adress": "Address",
      "adress_required": "Address is required",
      "phone": "Phone",
      "phone_required": "Enter the full phone number",
      "education": "Education",
      "education_required": "Education is required",
      "marital_status": "Marriage status",
      "marital_status_required": "Marriage status is required",
      "password": "Password",
      "password_required": "Password is required",
      "password_length_required": "Password is short",
      "old_password": "Old password",
      "old_password_required": "Old password is required",
      "password_confirmation": "Password confirmation",
      "password_confirmation_required": "Password confirmation is required",
      "password_confirmation_not_same": "It is not same password",
      "image": "Your photo",
      "image_required": "Photo is required"
    },
    "settings": {
      "personal": "Personal info",
      "succesPasswordChange": "Password changed successfully",
      "change_password": "Change password",
      "support": "Tech support",
      "change_lang": "Change language",
      "pravicy_policy": "Privacy and policy",
      "image": "Accepting image formats",
      "select_lang": "Select language"
    }
  },
  oz: {
    "lang": "oz",
    "pay": "Тўлаш",
    "login": {
      "welcome": "Хуш келибсиз",
      "missed": "Сизни сўғинишди",
      "signin": "Кириш",
      "no_account": "Аккаунтиз йукми?",
      "register": "Рўйхатдан ўтқаз",
      "create": "Бошлаш учун янги аккаунт яратинг",
      "have_account": "Аккаунтиз борми?",
      "click": "Буерни босинг",
      "confromation": "Тасдиқлаш",
      "message": "Биз сизнинг телефонингизга код юбордик. Кодни тасдиқланг",
      "resend": "Кодни қайта юбориш",
      "get_code": "Кодни олмадингизми?",
      "reset_password": "Паролингиз есдан чиқдими",
    },
    "welcome": "Хуш келибсиз",
    "continue": "Давом эттириш",
    "upload": "Юклаш",
    "update": "Янгилаш",
    "no_news": "Янгиликлар йўқ",
    "no_notifications": "Билдиришномалар йўқ",
    "back": "Орқага",
    "menu": {
      "home": "Бош саҳифа",
      "overviews": "Overviews",
      "news": "Янгиликлар",
      "settings": "Созламалар",
      "notifications": "Билдиришномалар",
      "logOut": "Чиқиш"
    },
    "application": {
      "tariffs": "Тарифлар",
      "add_child": "Қўшиш",
      "add": "Анкетани қўшиш",
      "payment": "Тўлов",
      "pay": "Тўлаш",
      "add_kid": "Болани қўшиш",
      "add_spouse": "Умурйўлдошни қўшиш",
      "total_payment": "Умумий тўлов",
      "pay_from_click": "Click UP орқали тўлаш",
      "pay_from_payme": "from Payme орқали тўлаш",
      "pay_from_wallet": "Ҳамёндан тўлаш",
      "pay_wallet_check": "Сиз ҳақиқатдан ҳам ҳамёндан тўлайсизми?",
      "total": "Жами",
      "wallet": "Ҳамён",
      "promo_code": "Промо код қўшиш",
      "promo": "Промо кодни киритинг",
      "promo_header": "Промо код",
      "code_required": "Промо код мажбурий",
      "delete": "Очиришни хоҳлайсизми?",
      "cancel": "Бекор қилиш",
      "confirm": "Очириш",
      "submit": "Юбориш",
      "upload_image": "Расм юклаш",
      "rule": "Фотосурат юклашнинг қисқача қоидалари",
      "rule_text": "ДИQQAT: Уй шароитида туширилган расмларни юкламанг. Расм фотосалонда туширилган бўлиши керак. <br />\n Юкланайотган фотосурат максимал ҳажми 240 KB дан ошмаслиги. <br />\n Фотосурат кенглиги камида 600x600 пиксел ёки 5x5 см бўлиши лозим. <br />\n Фотосурат фони ўқ ва равшан бўлиши лозим.",
      "save_image": "Расмни сақлаш",
      "more": "Батафсил",
      "empty": "Ҳеч нарса топилмади",
    },
    "not_found": "Opps саҳифаси топилмади",
    "home": {
      "menu": {
        "all": "Барчаси",
        "free": "Тўланмаган",
        "paid": "Тўланган"
      },
      "add": "Янги қўшиш"
    },
    "inputs": {
      "fname": "Исм",
      "fname_required": "Исм мажбурий",
      "sname": "Фамиля",
      "sname_required": "Фамилия мажбурий",
      "birthday": "Туғилган сана",
      "birthday_required": "Туғилган сана мажбурий",
      "gender": "Жинс",
      "gender_required": "Жинс мажбурий",
      "live_country": "Яшаш мамлакат",
      "live_country_required": "Яшаш мамлакат мажбурий",
      "live_region": "Яшаш вилояти",
      "live_region_required": "Яшаш вилояти мажбурий",
      "live_district": "Яшаш тумани",
      "live_district_required": "Яшаш тумани мажбурий",
      "no_country": "Илтимос олдин давлатингизни танланг",
      "no_region": "Илтимос олдин вилоятингизни танланг",
      "passport": "Паспорт рақами",
      "passport_required": "Паспорт рақами мажбурий",
      "passport_exp": "Паспорт амал қилиш мудати",
      "passport_exp_required": "Паспорт амал қилиш мудати мажбурий",
      "photo": "Расм",
      "birth_country": "Туғилган мамлакат",
      "birth_country_required": "Туғилган мамлакат мажбурий",
      "birth_region": "Туғилган вилоят",
      "birth_region_required": "Туғилган вилоят мажбурий",
      "passport_given": "Паспорт берилган давлат",
      "passport_given_required": "Паспорт берилган давлат мажбурий",
      "adress": "Манзил",
      "adress_required": "Манзил мажбурий",
      "phone": "Телефон",
      "phone_required": "Телефон рақами тўлиқ киргизингиз",
      "education": "Маълумотингиз",
      "education_required": "Маълумотингиз киритинг",
      "marital_status": "Оилавий ҳолат",
      "marital_status_required": "Оилавий ҳолатни киритинг",
      "password": "Парол",
      "password_required": "Паролни киритинг",
      "password_length_required": "Парол жуда қисқа",
      "old_password": "Ески парол",
      "old_password_required": "Ески паролни киритинг",
      "password_confirmation": "Парол қайта кириш",
      "password_confirmation_required": "Паролни киритинг",
      "password_confirmation_not_same": "Пароллар бир бирга мувофиқ эмас",
      "image": "Расм",
      "image_required": "Расмни юкланг"
    },
    "settings": {
      "personal": "Шахсий маълумот",
      "succesPasswordChange": "Парол янгиланди",
      "change_password": "Паролни янгилаш",
      "support": "Техник ёрдам",
      "change_lang": "Тилни алмаштириш",
      "pravicy_policy": "Махфийлик ва сиёсат",
      "image": "Расм форматлари",
      "select_lang": "Тилни танлаш"
    }
  },
  uz: {
    "lang": "uz",
    "pay": "To'lash",
    "login": {
      "welcome": "Xush Kelibsiz",
      "missed": "Sizni sog'inishdi",
      "signin": "Kirish",
      "no_account": "Akkauntiz yuqmi?",
      "register": "Ro'yxatdan ot'sh",
      "create": "Boshlash uchun yangi akkaunt yarating",
      "have_account": "Akkauntiz bormi?",
      "click": "Buyerni bosing",
      "confromation": "Tasdiqlash",
      "message": "Biz sizning telefoningizga kod yubordik. Kodni tasdiqlang",
      "resend": "Kodni qayta yuborish",
      "get_code": "Kodni olmadingizmi?",
      "reset_password": "Parolingiz esdan chiqdimi",
    },
    "welcome": "Xush kelibsiz",
    "continue": "Davom etish",
    "upload": "Yuklash",
    "update": "Yangilash",
    "no_news": "Yangiliklar yo'q",
    "no_notifications": "Bildirishnomalar yo‘q",
    "back": "Orqaga",
    "menu": {
      "home": "Bosh sahifa",
      "overviews": "Overviews",
      "news": "Yangiliklar",
      "settings": "Sozlamalar",
      "notifications": "Bildirishnomalar",
      "logOut": "Chiqish"
    },
    "application": {
      "tariffs": "Tariflar",
      "add_child": "Qo'shish",
      "add": "Anketani qo'shish",
      "payment": "To'lov",
      "pay": "To'lash",
      "add_kid": "Bolani qo'shish",
      "add_spouse": "Umuryo'ldoshni qo'shish",
      "total_payment": "Umumiy to'lov",
      "pay_from_click": "Click UP orqali to'lash",
      "pay_from_payme": "Payme  orqali to'lash",
      "pay_from_wallet": "Hamyondan to'lash",
      "pay_wallet_check": "Siz haqiqatdan ham hamyondan to'laysizmi?",
      "total": "Jami",
      "wallet": "Hamyon",
      "promo_code": "Promo kod qo'shish",
      "promo": "Promo kodni kiriting",
      "promo_header": "Promo kod",
      "code_required": "Promo kod majburiy",
      "delete": "O'chirishni xohlaysizmi?",
      "cancel": "Bekor qilish",
      "confirm": "O'chirish",
      "submit": "Yuborish",
      "upload_image": "Rasm yuklash",
      "rule": "Fotosurat yuklashning qisqacha qoidalari",
      "rule_text": "DIQQAT: Uy sharoitida tushirilgan rasmlarni yuklamang. Rasm fotosalonda tushirilgan bo'lishi kerak. <br />\n      Yuklanayotgan fotosurat maksimal hajmi 240 KB dan oshmasligi. <br />\n      Fotosurat kengligi kamida 600x600 piksel yoki 5x5 sm bo'lishi lozim. <br />\n      Fotosurat foni oq va ravshan bo'lishi lozim.",
      "save_image": "Rasmni saqlash",
      "more": "Batafsil",
      "empty": "Hech narsa topilmadi",
    },
    "not_found": "Opps sahifasi topilmadi",
    "home": {
      "menu": {
        "all": "Barchasi",
        "free": "To'lanmagan",
        "paid": "To'langan"
      },
      "add": "Yangi qo'shish"
    },
    "inputs": {
      "fname": "Ism",
      "fname_required": "Ism majburiy",
      "sname": "Familya",
      "sname_required": "Familiya majburiy",
      "birthday": "Tug'ilgan sana",
      "birthday_required": "Tug'ilgan sana majburiy",
      "gender": "Jins",
      "gender_required": "Jins majburiy",
      "live_country": "Yashash mamlakati",
      "live_country_required": "Yashash mamlakat is majburiy",
      "live_region": "Yashash viloyati",
      "live_region_required": "Yashash viloyati majburiy",
      "live_district": "Yashash tumani",
      "live_district_required": "Yashash tumani majburiy",
      "passport": "Passport raqami",
      "passport_required": "Passport raqami majburiy",
      "passport_exp": "Passport amal qilish mudati",
      "passport_exp_required": "Passport amal qilish mudati majburiy",
      "photo": "Rasm",
      "birth_country": "Tug'ilgan mamlakat",
      "birth_country_required": "Tug'ilgan mamlakat majburiy",
      "no_country": "Iltimos oldin davlatingizni tanlang",
      "no_region": "Iltimos oldin viloyatingizni tanlang",
      "birth_region": "Tug'ilgan viloyat",
      "birth_region_required": "Tug'ilgan viloyat majburiy",
      "passport_given": "Passport berilgan davlat",
      "passport_given_required": "Passport berilgan davlat majburiy",
      "adress": "Manzil",
      "adress_required": "Manzil majburiy",
      "phone": "Telefon",
      "phone_required": "Telefon raqami to'liq kirgizing",
      "education": "Ma'lumotingiz",
      "education_required": "Ma'lumotingiz kiriting",
      "marital_status": "Oilaviy holat",
      "marital_status_required": "Oilaviy holatni kiriting",
      "password": "Parol",
      "password_required": "Parolni kiriting",
      "password_length_required": "Parol juda qisqa",
      "old_password": "Eski parol",
      "old_password_required": "Eski parolni kiriting",
      "password_confirmation": "Parol qayta kirish",
      "password_confirmation_required": "Parolni kiriting",
      "password_confirmation_not_same": "Parollar bir birga muvofiq emas",
      "image": "Rasm",
      "image_required": "Rasmni yuklang"
    },
    "settings": {
      "personal": "Shaxsiy ma'lumot",
      "succesPasswordChange": "Parol yangilandi",
      "change_password": "Parolni yangilash",
      "support": "Texnik yordam",
      "change_lang": "Tilni almashtirish",
      "pravicy_policy": "Maxfiylik va siyosat",
      "image": "Rasm formatlari",
      "select_lang": "Tilni tanlash"
    }
  },
  ru: {
    "lang": "ru",
    "pay": "Оплатить",
    "login": {
      "welcome": "Добро пожаловать",
      "missed": "Мы соскучились по вам",
      "signin": "Войти",
      "no_account": "Нет аккаунта?",
      "register": "Зарегистрироваться",
      "create": "Создать новый аккаунт",
      "have_account": "Уже есть аккаунт?",
      "click": "Нажмите здесь",
      "confirmation": "Подтверждение",
      "message": "Мы отправили код на ваш телефон. Подтвердите его",
      "resend": "Переотправить позже",
      "get_code": "Не получили код?",
      "reset_password": "Забыли пароль",
    },
    "welcome": "Добро пожаловать",
    "continue": "Продолжить",
    "upload": "Загрузить",
    "update": "Обновить",
    "no_news": "Нет новостей",
    "no_notifications": "Нет уведомлений",
    "back": "Назад",
    "menu": {
      "home": "Главная",
      "overviews": "Обзоры",
      "news": "Новости",
      "settings": "Настройки",
      "notifications": "Уведомления",
      "logOut": "Выйти"
    },
    "application": {
      "tariffs": "Тарифы",
      "add_child": "Добавить ребенка",
      "add": "Добавить заявку",
      "payment": "Оплата",
      "pay": "Оплатить",
      "add_kid": "Добавить ребенка",
      "add_spouse": "Добавить супруга",
      "total_payment": "Общая сумма оплаты",
      "pay_from_click": "Оплата через Click UP",
      "pay_from_payme": "Оплата через Payme",
      "pay_from_wallet": "Оплатить из кошелька",
      "pay_wallet_check": "Вы действительно платите из кошелька?",
      "total": "Итог",
      "wallet": "Кошелек",
      "promo_code": "Добавить промо-код",
      "promo": "Введите промо-код",
      "promo_header": "Добавить промо-код",
      "code_required": "Необходим промо-код",
      "delete": "Вы хотите удалить?",
      "cancel": "Отмена",
      "confirm": "Удалить",
      "submit": "Отправить",
      "upload_image": "Загрузить изображение",
      "rule": "Краткие правила загрузки фото",
      "rule_text": "ВНИМАНИЕ: Не загружайте фотографии, сделанные дома. Фотография должна быть сделана в фотостудии. <br />\n      Максимальный размер загружаемого фото не должен превышать 240 КБ. <br />\n      Размер фотографии должен быть не менее 600x600 пикселей или 5x5 см. <br />\n      Фон фотографии должен быть светлым и равномерным.",
      "save_image": "Сохранить изображение",
      "more": "Более",
      "empty": "Ничего не найдено",
    },
    "not_found": "Упс! Страница не найдена",
    "home": {
      "menu": {
        "all": "Все",
        "free": "Неоплачиваемый",
        "paid": "Оплаченный"
      },
      "add": "Добавить новое"
    },
    "inputs": {
      "fname": "Имя",
      "fname_required": "Имя обязательно",
      "sname": "Фамилия",
      "sname_required": "Фамилия обязательна",
      "birthday": "Дата рождения",
      "birthday_required": "Дата рождения обязательна",
      "gender": "Пол",
      "gender_required": "Пол обязателен",
      "live_country": "Страна проживания",
      "live_country_required": "Страна проживания обязательна",
      "live_region": "Регион проживания",
      "live_region_required": "Регион проживания обязателен",
      "live_district": "Район проживания",
      "live_district_required": "Район проживания обязателен",
      "no_country": "Сначала выберите свою страну",
      "no_region": "Сначала выберите свой регион",
      "passport": "Номер паспорта",
      "passport_required": "Номер паспорта обязателен",
      "passport_exp": "Срок действия паспорта",
      "passport_exp_required": "Срок действия паспорта обязателен",
      "photo": "Ваше фото",
      "birth_country": "Страна рождения",
      "birth_country_required": "Страна рождения обязательна",
      "birth_region": "Регион рождения",
      "birth_region_required": "Регион рождения обязателен",
      "passport_given": "Страна выдачи паспорта",
      "passport_given_required": "Страна выдачи паспорта обязательна",
      "address": "Адрес",
      "address_required": "Адрес обязателен",
      "phone": "Телефон",
      "phone_required": "Телефон обязателен",
      "education": "Образование",
      "education_required": "Образование обязательно",
      "marital_status": "Семейное положение",
      "marital_status_required": "Семейное положение обязательно",
      "password": "Пароль",
      "password_required": "Пароль обязателен",
      "password_length_required": "Пароль слишком короткий",
      "old_password": "Старый пароль",
      "old_password_required": "Старый пароль обязателен",
      "password_confirmation": "Подтверждение пароля",
      "password_confirmation_required": "Подтверждение пароля обязательно",
      "password_confirmation_not_same": "Пароли не совпадают",
      "image": "Ваше фото",
      "image_required": "Фото обязательно"
    },
    "settings": {
      "personal": "Личная информация",
      "successPasswordChange": "Пароль успешно изменен",
      "change_password": "Изменить пароль",
      "support": "Техническая поддержка",
      "change_lang": "Изменить язык",
      "pravicy_policy": "Политика конфиденциальности",
      "image": "Принимаемые форматы изображений",
      "select_lang": "Выбрать язык"
    }
  }
};
const langSlice = createSlice({
  name: "lang",
  initialState: localStorage.getItem("lang")
    ? initalState[localStorage.getItem("lang")]
    : initalState.uz,
  reducers: {
    changeLang(state, action) {
      return initalState[action.payload];
    },
  },
});

export const { changeLang } = langSlice.actions;
export default langSlice.reducer;
