import { useEffect, useState, useRef } from 'react'
import InputFeild from '../../components/InputFeild/InputFeild'
import { Col, Row, Spinner } from 'react-bootstrap';
import useGet from '../../components/httpHook/useGet';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { changePassport, changePhone } from '../../components/Externel functions/specailHandleChange';
import { dotDate } from '../../components/Externel functions/dotDate';
import { gender } from '../../components/Externel functions/constants';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-multi-date-picker';
import transition from "react-element-popper/animations/transition"
import CustomMultipleInput from '../../components/Datepicker';
import UploadImage from '../../components/modals/imageModal';

function AddApplication() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [regions, setRegions] = useState([]);
    const [live_regions, setLive_regions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const formRef = useRef();

    /* Session storagedan userning ma'lumotlarni yuklab olish */
    let application = [];
    if (sessionStorage.getItem("applications") !== "undefined") {
        const decodeStorageData = JSON.parse(sessionStorage.getItem("applications"));
        application = decodeStorageData.find(item => item.id === +params.get("id"));

    }
    const [imageId, setImageId] = useState(application?.file?.id);
    // User data state
    const [user] = useState({
        type: "parent",
        first_name: application?.first_name ? application.first_name : "",
        last_name: application?.last_name ? application.last_name : "",
        birth_date: application?.birth_date ? application.birth_date : "",
        gender: (application?.gender || application?.gender === 0) ? application?.gender : '',
        birth_country_id: application?.birth_country?.id ? application.birth_country.id : '',
        birth_region_id: application?.birth_region?.id ? application.birth_region.id : null,
        live_country_id: application?.live_country?.id ? application.live_country.id : '',
        live_region_id: application?.live_region?.id ? application.live_region.id : null,
        live_district_id: application?.live_district?.id ? application.live_district.id : null,
        address: application?.address ? application?.address : "",
        passport: application?.passport ? changePassport(application?.passport) : "",
        passport_exp_date: application?.passport_exp_date ? application.passport_exp_date : "",
        phone: application?.phone ? changePhone(application?.phone) : "+998",
        passport_given_country_id: application?.passport_given_country?.id ? application.passport_given_country.id : '',
        marital_status_id: application?.marital_status?.id ? application.marital_status.id : '',
        education_level_id: application?.education_level?.id ? application.education_level.id : '',
        file: application?.file ? application?.file : "",
        tariff_id: sessionStorage.getItem("tariff_id")
    });

    const { register, formState: { errors }, watch, control, reset, handleSubmit } = useForm({
        defaultValues: user,
    })

    const img_url = watch("file")

    const lang = useSelector(state => state.lang)
    const [countries] = useGet("countries", true);
    const [education] = useGet("education-levels?", true);
    const [ismarred] = useGet("martial-statuses?", true);

    // O'zgaritish uchun kirilganda tug'ulgan davlatini viloyatlarni yuklab olish
    useEffect(() => {
        if (user.birth_country_id !== "") {
            axios.get(`countries?parent_id=${user.birth_country_id}&_lang=${lang.lang}`).then(res => {
                setRegions(res.data.data.map(item => ({ value: item.id, label: item.title })))
            })
        }
    }, [user.birth_country_id, lang.lang])

    // Foydalanuchi tug'ulgan davlatini tanalaganda viloyatlarni yuklab olish
    function getBirthRegions(countryId) {
        axios.get(`countries?parent_id=${countryId}&_lang=${lang.lang}`).then(res => {
            setRegions(res.data.data.map(item => ({ value: item.id, label: item.title })))
        })
    }
    // O'zgaritish uchun kirilganda viloyatlarni yuklab olish
    useEffect(() => {
        if (user.live_country_id) {
            axios.get(`countries?parent_id=${user.live_country_id}&_lang=${lang.lang}`).then(res => {
                setLive_regions(res.data.data.map(item => ({ value: item.id, label: item.title })))
            })
        }
    }, [user.live_country_id, lang.lang])

    // Foydalanuchi yashash davlatini tanalaganda viloyatlarni yuklab olish
    function getRegions(countryId) {
        axios.get(`countries?parent_id=${countryId}&_lang=${lang.lang}`).then(res => {
            setLive_regions(res.data.data.map(item => ({ value: item.id, label: item.title })))
        })
    }

    // O'zgaritish uchun kirilganda tumanlarni yuklab olish
    useEffect(() => {
        if (user.live_region_id) {
            axios.get(`countries?parent_id=${user.live_region_id}&_lang=${lang.lang}`).then(res => {
                setDistricts(res.data.data.map(item => ({ value: item.id, label: item.title })))
            })
        }
    }, [user.live_region_id, lang.lang])

    // Foydalanuchi viloyatni tanalaganda tumanlarni yuklab olish
    function getDistricts(regionId) {
        axios.get(`countries?parent_id=${regionId}&_lang=${lang.lang}`).then(res => {
            setDistricts(res.data.data.map(item => ({ value: item.id, label: item.title })))
        })
    }

    function onSubmit(data) {
        setLoading(true)
        const submitData = {
            ...data,
            file_id: imageId,
            birth_date: typeof data.birth_date === "string" ? data.birth_date : dotDate(data.birth_date),
            passport_exp_date: typeof data.passport_exp_date === "string" ? data.passport_exp_date : dotDate(data.passport_exp_date),
            passport: data.passport.replace(/\s/g, ''),
            phone: data.phone.replace(/\D/g, ''),
            parent_id: params.get("parent_id") !== "null" ? params.get("parent_id") : null,
        }
        delete submitData.file

        if (params.get("type") === "change") {
            axios.patch(`infos/${params.get("id")}`, submitData)
                .then(res => {
                    res.data.data.parent_id !== null ?
                        navigate("/application/pay-add?id=" + res.data.data.parent_id, { replace: true }) :
                        navigate("/application/pay-add?id=" + res.data.data.id, { replace: true })

                })
        } else {
            axios.post("infos", submitData)
                .then(res => {
                    setLoading(false)
                    res.data.data.parent_id !== null ?
                        navigate("/application/pay-add?id=" + res.data.data.parent_id, { replace: true }) :
                        navigate("/application/pay-add?id=" + res.data.data.id, { replace: true })
                })
                .catch(err => {
                    setLoading(false)
                    setError(err.response.data.error)
                })
        }
    }

    return (
        <div>
            <h2 className='mb-4'>{lang.application.add}</h2>
            <form name='application' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Row xs={1} lg={2}>
                    <Col>
                        {/* First name input */}
                        <InputFeild
                            placeholder={lang.inputs.fname}
                            data={register("first_name", { required: lang.inputs.fname_required })}
                            error={errors.first_name} />

                        {/* Last name input */}
                        <InputFeild
                            placeholder={lang.inputs.sname}
                            data={register("last_name", { required: lang.inputs.sname_required })}
                            error={errors.last_name} />

                        {/* Data of birth */}
                        <div className="input-fields">
                            <Controller
                                name='birth_date'
                                rules={{ required: lang.inputs.birthday_required }}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        animations={[
                                            transition()
                                        ]}
                                        render={<CustomMultipleInput placeholder={lang.inputs.birthday} />}
                                        onChange={(date) => onChange(date)}
                                        headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                                        value={value}
                                        format="DD.MM.YYYY"
                                    />
                                )}
                            />
                            {errors?.birth_date?.message && <span className='select-error-text text-danger'>{errors?.birth_date?.message}</span>}
                        </div>

                        {/* Gender Select */}
                        <div className="input-fields">
                            <Controller
                                name='gender'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.gender}
                                        className="custom-select"
                                        options={gender[lang.lang]}
                                        onChange={(val) => onChange(val.value)}
                                        value={value === undefined ? gender[lang.lang]?.find(item => +item.value === +user.gender) : gender[lang.lang]?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.gender_required }}
                            />
                            {errors?.gender?.message && <span className='select-error-text text-danger'>{errors?.gender?.message}</span>}
                        </div>

                        {/* Live country Select */}
                        <div className="input-fields">
                            <Controller
                                name='live_country_id'
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Select
                                            placeholder={lang.inputs.live_country}
                                            className="custom-select"
                                            options={countries}
                                            onChange={(val) => {
                                                onChange(val.value)
                                                reset((formData) => ({
                                                    ...formData,
                                                    live_district_id: "",
                                                    live_region_id: ""
                                                }))
                                                getRegions(val.value)
                                            }}
                                            value={!value ? countries?.find(item => +item.value === +user.gender) : countries?.find(item => item.value === value)}
                                        />)
                                }
                                }
                                rules={{ required: lang.inputs.live_country_required }}
                            />
                            {errors?.live_country_id?.message && <span className='select-error-text text-danger'>{errors?.live_country_id?.message}</span>}
                        </div>

                        {/* Live region Select */}
                        <div className="input-fields">
                            <Controller
                                name='live_region_id'
                                control={control}
                                render={({ field: { onChange, value } }) =>
                                    <Select
                                        placeholder={lang.inputs.live_region}
                                        className="custom-select"
                                        options={live_regions}
                                        noOptionsMessage={() => lang.inputs.no_country}
                                        onChange={(val) => {
                                            onChange(val.value);
                                            reset((formData) => ({
                                                ...formData,
                                                live_district_id: "",
                                            }))
                                            getDistricts(val.value)
                                        }}
                                        value={value === "" ? null : user?.live_region_id ? live_regions?.find(item => +item.value === +user.live_region_id) : live_regions?.find(item => item.value === value)}
                                    />
                                }
                                rules={{ required: lang.inputs.live_region_required }}
                            />
                            {errors?.live_region_id?.message && <span className='select-error-text text-danger'>{errors?.live_region_id?.message}</span>}
                        </div>

                        {/* Live district Select */}
                        <div className="input-fields">
                            <Controller
                                name='live_district_id'
                                control={control}
                                render={({ field: { onChange, value } }) =>
                                    <Select
                                        placeholder={lang.inputs.live_district}
                                        className="custom-select"
                                        options={districts}
                                        noOptionsMessage={() => lang.inputs.no_region}
                                        onChange={(val) => onChange(val.value)}
                                        value={value === "" ? null : user?.live_district_id ? districts?.find(item => +item.value === +user.live_district_id) : districts?.find(item => item.value === value)}
                                    />
                                }
                                rules={{ required: lang.inputs.live_district_required }}
                            />
                            {errors?.live_district_id?.message && <span className='select-error-text text-danger'>{errors?.live_district_id?.message}</span>}
                        </div>

                        <InputFeild
                            placeholder={lang.inputs.passport} type="text"
                            data={register("passport", { required: lang.inputs.passport_required, onChange: (e) => e.target.value = changePassport(e.target.value) })}
                            error={errors.passport} />

                        {/* Data of birth */}
                        <div className="input-fields">
                            <Controller
                                name='passport_exp_date'
                                rules={{ required: lang.inputs.passport_exp_required }}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        animations={[
                                            transition()
                                        ]}
                                        render={<CustomMultipleInput placeholder={lang.inputs.passport_exp} />}
                                        onChange={(date) => onChange(date)}
                                        headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                                        value={value}
                                        format="DD.MM.YYYY"
                                    />
                                )}
                            />
                            {errors?.passport_exp_date?.message && <span className='select-error-text text-danger'>{errors?.passport_exp_date?.message}</span>}
                        </div>
                    </Col>
                    <Col>
                        {/* Image upload modal */}
                        <div className="input-fields">
                            <UploadImage setImageId={setImageId} imageId={imageId} file={img_url} register={register} />
                            {errors?.file?.message && <span className='select-error-text bottom-0 text-danger'>{errors?.file?.message}</span>}
                        </div>
                        {/* birth regions Select */}
                        <div className="input-fields">
                            <Controller
                                name='birth_country_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.birth_country}
                                        className="custom-select"
                                        options={countries}
                                        onChange={(val) => {
                                            onChange(val.value);
                                            reset((formData) => ({
                                                ...formData,
                                                birth_region_id: "",
                                            }))
                                            getBirthRegions(val.value)
                                        }}
                                        value={!value ? countries?.find(item => +item.value === +user.birth_country_id) : countries?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.birth_country_required }}
                            />
                            {errors?.birth_country_id?.message && <span className='select-error-text text-danger'>{errors?.birth_country_id?.message}</span>}
                        </div>
                        {/* birth regions Select */}
                        <div className="input-fields">
                            <Controller
                                name='birth_region_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.birth_region}
                                        className="custom-select"
                                        options={regions}
                                        noOptionsMessage={() => lang.inputs.no_country}
                                        onChange={(val) => onChange(val.value)}
                                        value={value === "" ? null : user?.birth_region_id ? regions?.find(item => +item.value === +user.birth_region_id) : regions?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.birth_region }}
                            />
                            {errors?.birth_region_id?.message && <span className='select-error-text text-danger'>{errors?.birth_region_id?.message}</span>}
                        </div>

                        {/* passport given country Select */}
                        <div className="input-fields">
                            <Controller
                                name='passport_given_country_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.passport_given}
                                        className="custom-select"
                                        options={countries}
                                        onChange={(val) => onChange(val.value)}
                                        value={!value ? countries?.find(item => +item.value === +user.passport_given_country_id) : countries?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.passport_given_required }}
                            />
                            {errors?.passport_given_country_id?.message && <span className='select-error-text text-danger'>{errors?.passport_given_country_id?.message}</span>}
                        </div>

                        <InputFeild
                            placeholder={lang.inputs.adress}
                            data={register("address", { required: lang.inputs.adress_required })}
                            type="textarea"
                            error={errors.address} />

                        <InputFeild
                            data={register("phone", { required: true, minLength: { value: 17, message: lang.inputs.phone_required }, onChange: (e) => e.target.value = changePhone(e.target.value) })}
                            placeholder={lang.inputs.phone}
                            type="text"
                            error={errors?.phone} />

                        {/* Education Select */}
                        <div className="input-fields">
                            <Controller
                                name='education_level_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.education}
                                        className="custom-select"
                                        options={education}
                                        onChange={(val) => onChange(val.value)}
                                        value={!value ? education?.find(item => +item.value === +user.education_level_id) : education?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.education_required }}
                            />
                            {errors?.education_level_id?.message && <span className='select-error-text text-danger'>{errors?.education_level_id?.message}</span>}
                        </div>

                        {/* marital status Select */}
                        <div className="input-fields">
                            <Controller
                                name='marital_status_id'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        placeholder={lang.inputs.marital_status}
                                        className="custom-select"
                                        options={ismarred}
                                        onChange={(val) => onChange(val.value)}
                                        value={!value ? ismarred?.find(item => +item.value === +user.marital_status_id) : ismarred?.find(item => item.value === value)}
                                    />)
                                }
                                rules={{ required: lang.inputs.marital_status_required }}
                            />
                            {errors?.marital_status_id?.message && <span className='select-error-text text-danger'>{errors?.marital_status_id?.message}</span>}
                        </div>
                    </Col>
                </Row>
                <p className='text-danger'>{error}</p>
                <button type='submit' className='button'>
                    {loading ? <Spinner className='me-2' animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : ""}
                    {lang.continue}</button>
            </form>
        </div>
    )
}

export default AddApplication