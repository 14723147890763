import { Modal } from 'react-bootstrap'
import InputFeild from '../InputFeild/InputFeild';
import { useState } from 'react';
import defaultImg from '../../assets/image/good_image.png'
import { Gallery } from 'iconsax-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import parser from 'html-react-parser'

function UploadImage({ register, file, setImageId, imageId }) {
    const [show, setShow] = useState(false);
    const lang = useSelector(state => state.lang)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function onSubmit() {
        const formData = new FormData();
        formData.append("file", file[0]);
        axios.post("file", formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        }).then((res) => {
            setImageId(res.data.data.id)
            handleClose()
        }).catch(err => {
            handleClose()
            toast.error("Rasm saqlanmadi")
        })
    }
    return (
        <>
            <button type='button' className="input-feild" variant="primary" onClick={handleShow}>
                <Gallery size={26} color='#7a7a7a' />
                <span className='d-inline-block ms-2' style={{ color: "#7a7a7a" }}>{imageId ? imageId : lang.inputs.image} </span>
            </button>

            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-50 mx-auto mb-3'>
                        <img className='w-100' src={!file ? defaultImg : typeof file?.link === "string" ? file?.link : URL.createObjectURL(file[0])} alt="user" />
                    </div>
                    <InputFeild type={"file"} placeholder={lang.inputs.image} data={register("file", { required: lang.inputs.image_required })} />
                    <h4>{lang.application.rule}</h4>
                    <p>{parser(lang.application.rule_text)}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' onClick={onSubmit}>{lang.application.save_image}</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadImage;